import React from "react"
import SEO from "../components/seo"
import { Helmet } from "react-helmet";
import { graphql } from 'gatsby';


// do not remove this import, it loads the styles for the page
import Layout from "../components/layout";

class NotFoundPage extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8', 
            }
        }

    }

    render() {

        return (
            <>
                <Helmet>
                    
                </Helmet>
                <SEO title={`404: Not found`} description={`The magic of the holidays is here, and Santa Claus is closer than you think. Bring your family and friends for professional quality photos with Santa.`} />
                <h1>NOT FOUND</h1>
                <p>You just hit a route that doesn&#39;t exist... the sadness!</p>

            </>
        );
    }
}

export default NotFoundPage





